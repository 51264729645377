import { format, parseISO, formatDistanceToNow, isToday } from 'date-fns';
import { ar } from 'date-fns/locale';
import i18n from './i18n';

export const convertLocalToUTCDate = (date) => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
  return date;
};

export const convertUTCToLocalDate = (date) => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return date;
};

export const addDayToDate = (date) => {
  if (!date) {
    return date;
  }

  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);

  return newDate;
};

export const formatDate = (isoDateString, dateFormat = 'd MMM, yyy') => {
  if (!isoDateString) {
    return isoDateString;
  }

  const isAr = i18n.language === 'ar';
  const locale = isAr ? ar : undefined;

  return format(new Date(isoDateString), dateFormat, {
    locale,
  });
};

export const adjustToUTC = (originalDate) => {
  if (!originalDate) {
    return originalDate;
  }

  originalDate.setHours(
    originalDate.getHours() - originalDate.getTimezoneOffset() / 60
  );

  const utcString = originalDate.toISOString();

  return utcString;
};

export const utcStringToDate = (originalDate) => {
  if (!originalDate) {
    return originalDate;
  }

  return new Date(new Date(originalDate).setHours(0, 0, 0, 0));
};

export const isNA = (date) => date === 'N/A';

export const formatDateDistance = (dateString) => {
  if (!dateString) {
    return dateString;
  }

  const isAr = i18n.language === 'ar';
  const locale = isAr ? ar : undefined;

  const date = parseISO(dateString);
  const distance = formatDistanceToNow(date, { addSuffix: true, locale });

  if (isToday(date)) {
    return i18n.t('Today');
  }

  if (distance === 'in 1 day' || distance === '1 day ago') {
    return 'Yesterday';
  }

  return distance;
};

export const renderDate = (date) => (isNA(date) ? date : formatDate(date));
