import { Flex, useDisclosure } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Navbar from './Navbar';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Reports } from 'assets/icons/diagram.svg';
import { ReactComponent as SkuGroup } from 'assets/icons/frame.svg';
import { ReactComponent as GridSquare } from 'assets/icons/grid-square.svg';
import { ReactComponent as Inbound } from 'assets/icons/inbound.svg';
import { ReactComponent as Addresses } from 'assets/icons/location-minus.svg';
import { ReactComponent as Outbound } from 'assets/icons/outbound.svg';
import { ReactComponent as Sku } from 'assets/icons/scanner.svg';
import { ReactComponent as Transportation } from 'assets/icons/truck-fast.svg';
import { ReactComponent as Suppliers } from 'assets/icons/users.svg';
import { ReactComponent as Warehouse } from 'assets/icons/warehouse.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { AbilityContext } from 'context/AbilityContext';
import { defineAbilitiesFor } from 'utils/abilities';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';
import Onboarding from 'pages/Onboarding/Onboarding';
import { css, Global } from '@emotion/react';
import UnverifiedOrganizationAlert from 'components/Dashboard/UnverifiedOrganizationAlert';
import { useUnverifiedOrganizationAlert } from 'context/UnverifiedOrganizationAlert';
import { useQuery } from 'react-query';
import { getUser } from 'api/auth';

const SIDEBAR_SIZE = '250px';
const NAVBAR_HEIGHT = { base: 16, md: '88px' };

const pages = [
  { name: 'overview', icon: GridSquare, path: '/' },
  {
    name: 'warehouses',
    icon: Warehouse,
    isStrokeIcon: true,
    path: '/warehouses',
    pageTitle: 'warehousesAndQuotes',
    children: [
      {
        path: '/quotations',
      },
      {
        path: '/quote/:id',
        pageTitle: 'requestQuote',
      },
      {
        path: '/success',
        pageTitle: 'allWarehouses',
      },
    ],
  },
  {
    name: 'inbounds',
    icon: Inbound,
    isStrokeIcon: true,
    path: '/inbounds',
    children: [
      {
        pageTitle: 'inboundDetails',
        path: '/:id',
      },
      {
        pageTitle: 'inboundPages.requestNewInbound',
        path: '/create',
        isCreate: true,
      },
      {
        pageTitle: 'inboundPages.requestNewInbound',
        path: '/:id/assign',
        isCreate: true,
      },
      {
        pageTitle: 'editInbound',
        path: '/:id/edit',
        isCreate: true,
      },
    ],
  },
  {
    name: 'outbounds',
    icon: Outbound,
    isStrokeIcon: true,
    path: '/outbounds',
    children: [
      {
        pageTitle: 'outboundDetails',
        path: '/:id',
      },
      {
        pageTitle: 'outboundPages.requestNewOutbound',
        path: '/create',
        isCreate: true,
      },
      {
        pageTitle: 'outboundPages.requestNewOutbound',
        path: '/:id/assign',
        isCreate: true,
      },
      {
        pageTitle: 'editOutbound',
        path: '/:id/edit',
        isCreate: true,
      },
    ],
  },
  {
    name: 'transportations',
    icon: Transportation,
    isStrokeIcon: true,
    path: '/transportations',
    pageTitle: 'transportationPages.transportationOrders',
    children: [
      {
        pageTitle: 'requestNewTransportationOrder',
        path: '/create',
        isCreate: true,
      },
      {
        pageTitle: 'transportationDetails',
        path: '/:id',
      },
      {
        pageTitle: 'transportationOrderSummary',
        path: '/:id/summary',
        isCreate: true,
      },
    ],
  },
  {
    name: 'skus',
    icon: Sku,
    path: '/skus',
    pageTitle: 'skus',
    children: [
      {
        pageTitle: 'skuDetails',
        path: '/:id',
      },
      {
        pageTitle: 'skusPages.createNewSKU',
        path: '/create',
        isCreate: true,
      },
      {
        pageTitle: 'editSku',
        path: '/:id/edit',
        isCreate: true,
      },
      {
        pageTitle: 'associatedMovements',
        path: '/:id/associated-movements',
      },
    ],
  },
  {
    name: 'skuGroups',
    icon: SkuGroup,
    path: '/sku-groups',
    adminsOnly: true,
    children: [
      {
        pageTitle: 'createNewSKUGroup',
        path: '/create',
        isCreate: true,
      },
      {
        pageTitle: 'skuGroupDetails',
        path: '/:id',
        isCreate: true,
      },
    ],
  },
  {
    name: 'addresses',
    icon: Addresses,
    isStrokeIcon: true,
    path: '/addresses',
    children: [
      {
        pageTitle: 'addressesPage.addNewAddress',
        path: '/create',
      },
      {
        pageTitle: 'addressesPage.addressDetails',
        path: '/:id',
      },
    ],
  },
  { name: 'suppliers', icon: Suppliers, path: '/suppliers' },
  { name: 'Reports', icon: Reports, isStrokeIcon: true, path: '/reports' },
  {
    name: 'profile',
    path: '/profile',
    pageTitle: 'myProfile',
    isChildPage: true,
  },
  {
    name: 'settings',
    path: '/settings',
    icon: SettingsIcon,
    isStrokeIcon: true,
    bottom: true,
    noPadding: true,
    children: [
      {
        path: '/organization',
      },
      {
        path: '/user-management',
      },
      {
        path: '/account',
      },
      {
        path: '/push-notifications',
      },
      {
        path: '/billing',
      },
    ],
  },
];

const getActiveChildPage = (page, pathname, id) =>
  page?.children?.find((childPage) => {
    if (id) {
      return page.path + childPage.path.replace(':id', id) === pathname;
    }
    return page.path + childPage.path === pathname;
  });

const DashboardLayout = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { showUnverifiedOrganizationAlert, hideUnverifiedOrganizationAlert } =
    useUnverifiedOrganizationAlert();

  const { pathname } = useLocation();
  const { id } = useParams();

  const user = getLocalStorage('user') ?? {};

  const { refetch: refetchUser } = useQuery('user-me', getUser, {
    onSuccess: (data) => {
      if (data.organization?.verified === false) {
        showUnverifiedOrganizationAlert();
      } else {
        hideUnverifiedOrganizationAlert();
      }

      setLocalStorage('user', data);
    },
    refetchOnWindowFocus: false,
  });

  const isOnboardingFinished = user?.onboarding_finished;

  const activePage = pages.find(
    (page) =>
      page.path === pathname ||
      getActiveChildPage(page, pathname, id) ||
      pathname.includes(`${page.path}/${id}`)
  );

  const activeChildPage = getActiveChildPage(activePage, pathname, id);

  const pageTitle = t(
    activeChildPage?.pageTitle ?? activePage?.pageTitle ?? activePage?.name
  );

  const linkItems = pages.filter(
    (page) => !page.isChildPage && (!page.adminsOnly || user.role === 'admin')
  );

  const isCreatePage = !!activeChildPage?.isCreate;

  const ability = defineAbilitiesFor(user);

  return (
    <AbilityContext.Provider value={ability}>
      <Flex height="100vh" flexDirection="row">
        <Sidebar
          isOpen={isOpen}
          onClose={onClose}
          onToggle={onToggle}
          size={SIDEBAR_SIZE}
          navbarHeight={NAVBAR_HEIGHT}
          linkItems={linkItems}
          activeLink={activePage}
        />
        <Flex flex="1" height="100%" flexDirection="column" w="0">
          <Navbar
            onToggle={onToggle}
            height={NAVBAR_HEIGHT}
            pageTitle={pageTitle}
            isCreatePage={isCreatePage}
          />

          <UnverifiedOrganizationAlert />

          <Flex
            py={activePage.noPadding ? 0 : { base: 4, md: 6 }}
            px={activePage.noPadding ? 0 : { base: 4, md: 8 }}
            sx={{
              // adds space for Intercom widget so it doesn't override other elements
              paddingBottom: '100px !important',
            }}
            bg="gray.50"
            flex="1"
            flexDirection="column"
            overflowY="auto"
          >
            <Outlet context={{ pageTitle }} />

            {!isOnboardingFinished && (
              <>
                {/* to layer the intercom button above the onboarding modal */}
                <Global
                  styles={css`
                    .intercom-lightweight-app-launcher.intercom-launcher,
                    .intercom-lightweight-app,
                    .intercom-1qaopps,
                    .intercom-messenger-frame {
                      z-index: 1402 !important;
                    }
                  `}
                />
                <Onboarding onSuccess={refetchUser} />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </AbilityContext.Provider>
  );
};

export default DashboardLayout;
