import { BrowserRouter } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ChakraProvider } from '@chakra-ui/react';
import { theme, arTheme } from 'constants/theme';
import useLanguage from 'hooks/useLanguage';
import App from './App';
import { UnverifiedOrganizationModalProvider } from 'context/UnverifiedOrganizationModal';

import { PaymentDueProvider } from 'context/PaymentDueContext';
import NavigateSetter from 'components/NavigateSetter';
import { UnverifiedOrganizationAlertProvider } from 'context/UnverifiedOrganizationAlert';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'utils/queryClient';

const AppWithProviders = () => {
  const { language } = useLanguage();
  const currentTheme = language === 'en' ? theme : arTheme;

  return (
    <BrowserRouter>
      <NavigateSetter />
      <DndProvider backend={HTML5Backend}>
        <PaymentDueProvider>
          <ChakraProvider theme={currentTheme} portalZIndex={1401}>
            <UnverifiedOrganizationModalProvider>
              <UnverifiedOrganizationAlertProvider>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </UnverifiedOrganizationAlertProvider>
            </UnverifiedOrganizationModalProvider>
          </ChakraProvider>
        </PaymentDueProvider>
      </DndProvider>
    </BrowserRouter>
  );
};

export default AppWithProviders;
