import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Hide,
  Show,
  Stack,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useMediaQuery,
} from '@chakra-ui/react';
import BackToListButton from 'components/Dashboard/BackToListButton';

import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as RoutingIcon } from 'assets/icons/routing.svg';
import { ReactComponent as BoxIcon } from 'assets/icons/box.svg';
import { ReactComponent as SkuIcon } from 'assets/icons/scanner.svg';
import { ReactComponent as MerchantSkuIcon } from 'assets/icons/hashtag-up.svg';
import { ReactComponent as WarehouseIcon } from 'assets/icons/warehouse.svg';
import { ReactComponent as BrushIcon } from 'assets/icons/brush.svg';
import { ReactComponent as MaximizeIcon } from 'assets/icons/maximize-3.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/note-text-square.svg';
import { ReactComponent as InventoryThresholdIcon } from 'assets/icons/home-trend-down.svg';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import { useQuery } from 'react-query';
import { getSkuById } from 'api/Dashboard/skus';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import StatusTag from 'components/Dashboard/StatusTag';
import Carousel from 'components/shared/Carousel';
import OnHandInventoryList from 'components/Dashboard/SKUs/OnHandInventoryList';
import ReservedInventoryList from 'components/Dashboard/SKUs/ReservedInventoryList';
import ShippedInventoryList from 'components/Dashboard/SKUs/ShippedInventoryList';
import { ReactComponent as VelocityIcon } from 'assets/icons/fast-time.svg';

const SectionDataItem = ({ icon, label, value }) => (
  <Flex
    justifyContent="space-between"
    fontWeight={500}
    columnGap={{ base: 4, md: 20 }}
    rowGap={2}
    flexWrap={{ base: 'wrap', md: 'initial' }}
  >
    <Flex gap={3}>
      {icon}
      <Text color="gray.600" textTransform="capitalize">
        {label}
      </Text>
    </Flex>

    <Text>{value}</Text>
  </Flex>
);

const SkuDetails = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const { id } = useParams();

  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const { data: skuDetails = {}, isLoading } = useQuery(
    ['sku-details', id],
    () => getSkuById(id)
  );

  const skuDetailsItems = [
    {
      icon: <BoxIcon />,
      label: t('skuName'),
      value: skuDetails.name,
    },
    {
      icon: <SkuIcon />,
      label: t('sirdabSKU'),
      value: `#${skuDetails.system_sku}`,
    },
    {
      icon: <MerchantSkuIcon />,
      label: t('merchantSKU'),
      value: `#${skuDetails.merchant_sku}`,
    },
    {
      icon: <WarehouseIcon />,
      label: t('warehouseType'),
      value: t(skuDetails.sku_warehouse_type),
    },
  ];

  const emptyStringFallback = (string) => string || '-';

  const otherDetailsItems = [
    {
      icon: <BrushIcon />,
      label: t('color'),
      value: <StatusTag background="cyan.100"> {skuDetails.color}</StatusTag>,
    },
    {
      icon: <MaximizeIcon />,
      label: t('size'),
      value: `${emptyStringFallback(skuDetails.width)} * ${emptyStringFallback(
        skuDetails.height
      )} * ${emptyStringFallback(skuDetails.length)} ${t('CM')}`,
    },
    {
      icon: <SkuIcon />,
      label: t('value'),
      value: skuDetails.value,
    },
    {
      icon: <ListIcon />,
      label: t('categories'),
      value: skuDetails.categories?.map(
        ({ name }, index, list) =>
          `${name}${index === list.length - 1 ? '' : ', '}`
      ),
    },
    {
      icon: <NoteIcon />,
      label: t('description'),
      value: skuDetails.description,
    },
    {
      icon: (
        <Box width="24px" height="24px" flex={1}>
          <InventoryThresholdIcon />
        </Box>
      ),
      label: (
        <Text textTransform="none" as="span">
          {t('notifyMeWhenThresholdIsBelow')}
        </Text>
      ),
      value: (
        <Text as="span" whiteSpace="nowrap">
          {skuDetails.low_inventory_quantity === null
            ? '-'
            : `${skuDetails.low_inventory_quantity} ${t(
                skuDetails.low_inventory_unit
              )}`}
        </Text>
      ),
    },
    {
      icon: <VelocityIcon />,
      label: t('velocity'),
      value: skuDetails.velocity
        ? t(`skuVelocity.${skuDetails.velocity}`)
        : '-',
    },
  ];


  if (isLoading) {
    return <Spinner color="primary.500" />;
  }

  return (
    <Box>
      {skuDetails.is_below_threshold && (
        <Alert
          status="warning"
          sx={{
            width: `calc(100% + ${isMobile ? 32 : 64}px)`,
            top: `-${isMobile ? 16 : 24}px`,
            left: `-${isMobile ? 16 : 32}px`,
            _rtl: {
              left: 'auto',
              right: `-${isMobile ? 16 : 32}px`,
            },
          }}
          alignItems="start"
        >
          <AlertIcon />
          <Box>
            <AlertTitle width="full" margin={0}>
              {t('lowInventoryAlert')}
            </AlertTitle>
            <AlertDescription>
              {t('lowInventoryAlertDescription')}
            </AlertDescription>
          </Box>
        </Alert>
      )}
      <Stack spacing={6}>
        <Box>
          <Show below="md">
            <Box mb={8} mt={4}>
              <PageTitle title={pageTitle} fontWeight={600} />
            </Box>
          </Show>
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Hide below="md">
              <BackToListButton title={t('skusList')} url="/skus" />
            </Hide>
            <Flex gap={6} flex={1} justifyContent="flex-end" flexWrap="wrap">
              <Button
                leftIcon={<EditIcon />}
                colorScheme="gray"
                textTransform="capitalize"
                size="lg"
                bgColor="white"
                color="gray.600"
                width={{ base: 'full', md: 'auto' }}
                as={Link}
                to="edit"
              >
                {t('editSku')}
              </Button>

              <Button
                size="lg"
                colorScheme="primary"
                width={{ base: 'full', md: 'auto' }}
                leftIcon={<RoutingIcon />}
                as={Link}
                to="associated-movements"
              >
                {t('associatedMovements')}
              </Button>
            </Flex>
          </Flex>
        </Box>

        <Grid templateColumns="repeat(6, 1fr)" gap={6}>
          <GridItem
            colSpan={{ base: 6, md: 4 }}
            display="flex"
            flexFlow="column"
            gap={6}
          >
            <Card size="lg">
              <CardHeader>
                <SectionTitle title={t('skuDetails')} />
              </CardHeader>
              <CardBody>
                <Stack spacing={4}>
                  {skuDetailsItems.map(({ icon, label, value }, index) => (
                    <SectionDataItem
                      key={index}
                      icon={icon}
                      label={label}
                      value={value}
                    />
                  ))}
                </Stack>
              </CardBody>
            </Card>

            <Card size="lg">
              <CardHeader>
                <SectionTitle title={t('otherDetails')} />
              </CardHeader>
              <CardBody>
                <Stack spacing={4}>
                  {otherDetailsItems.map(({ icon, label, value }, index) => (
                    <SectionDataItem
                      key={index}
                      icon={icon}
                      label={label}
                      value={value}
                    />
                  ))}
                </Stack>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem colSpan={{ base: 6, md: 2 }}>
            <Card size="lg" height="full">
              <CardHeader>
                <SectionTitle title={t('skuImages')} />
              </CardHeader>
              <CardBody>
                <Box
                  width="full"
                  height="463px"
                  borderRadius="2xl"
                  overflow="hidden"
                  border="1px solid"
                  borderColor="gray.200"
                >
                  <Carousel
                    slides={
                      skuDetails.attachments?.map(
                        (attachment) => attachment.url
                      ) || []
                    }
                    imageProps={{
                      height: '463px',
                      width: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>

        <Card size="lg" height="full">
          <CardHeader>
            <SectionTitle title={t('inventoryDetails')} />
          </CardHeader>
          <CardBody>
            <Tabs
              size={{ base: 'md', md: 'lg' }}
              colorScheme="primary"
              isLazy
              variant={{ base: 'soft-rounded', md: 'line' }}
            >
              <TabList>
                <Tab>{t('onHand')}</Tab>
                <Tab>{t('reserved')}</Tab>
                <Tab>{t('shipped')}</Tab>
              </TabList>

              <TabPanels>
                <TabPanel paddingX={0}>
                  <OnHandInventoryList id={id} />
                </TabPanel>
                <TabPanel>
                  <ReservedInventoryList id={id} />
                </TabPanel>
                <TabPanel>
                  <ShippedInventoryList id={id} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  );
};

export default SkuDetails;
