import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  ModalFooter,
  Stack,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react';
import OptionalIndicator from 'components/shared/OptionalIndicator';
import Rating from 'components/shared/Rating';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const RatingModal = ({ isOpen, onClose, isOutbound, isLoading, onSubmit }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, control } = useForm();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="2xl">
        <ModalHeader padding={6} borderBottomWidth={1}>
          {t('helpUsToImproveYourExperience')}
        </ModalHeader>
        <ModalCloseButton
          background="gray.50"
          marginBlock={4}
          marginInlineEnd={2}
        />
        <ModalBody padding={6}>
          <form onSubmit={handleSubmit(onSubmit)} id="rating-form" noValidate>
            <fieldset disabled={isLoading ? 'disabled' : undefined}>
              <Stack spacing={6}>
                <Stack spacing={4} alignItems="center">
                  <Text fontSize="2xl" fontWeight={600}>
                    {t('yourOpinionMatters')}
                  </Text>
                  <Text fontWeight={500}>
                    {t('howSatisfiedAreYouWithThisValueOrder', {
                      value: t(isOutbound ? 'outboundOrder' : 'inboundOrder'),
                    })}
                  </Text>

                  <Controller
                    name="rating"
                    control={control}
                    rules={{ required: 'thisFieldIsRequired' }}
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <FormControl
                        isInvalid={error}
                        sx={{
                          display: 'flex',
                          flexFlow: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <Rating onChange={onChange} disabled={isLoading} />
                        <FormErrorMessage>{t(error?.message)}</FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Stack>

                <FormControl gap={2}>
                  <FormLabel
                    optionalIndicator={<OptionalIndicator />}
                    color="gray.700"
                  >
                    {t('additionalFeedback')}
                  </FormLabel>
                  <Textarea
                    borderRadius="xl"
                    backgroundColor="gray.50"
                    placeholder={t('pleaseShareMoreDetails')}
                    minHeight="100px"
                    _placeholder={{
                      fontSize: 'sm',
                    }}
                    {...register('comment')}
                  />
                </FormControl>
              </Stack>
            </fieldset>
          </form>
        </ModalBody>

        <ModalFooter borderTopWidth={1} padding={6} gap={4}>
          <Button
            minWidth="200px"
            backgroundColor="white"
            size="lg"
            variant="outline"
            onClick={onClose}
            isDisabled={isLoading}
          >
            {t('cancel')}
          </Button>

          <Button
            colorScheme="primary"
            size="lg"
            type="submit"
            form="rating-form"
            minWidth="200px"
            isLoading={isLoading}
          >
            {t('submitFeedback')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RatingModal;
