import { Spinner } from '@chakra-ui/react';
import { getSkuById, updateSKU } from 'api/Dashboard/skus';
import SKUFormPage from 'components/Dashboard/SKUs/SKUFormPage';
import { stringToNumber } from 'components/shared/Inputs/FileUpload/utils';
import { warehouseTypeEnum } from 'constants/dashboard';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { flattenErrorObject } from 'utils/formError';

const SKUEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: skuDetails = {}, isLoading } = useQuery(
    ['sku-details', id],
    () => getSkuById(id)
  );

  const {
    mutate: updateSKUMutation,
    isLoading: isUpdateSKULoading,
    error: updateSKUErrors,
  } = useMutation((payload) => updateSKU(id, payload), {
    onSuccess: ({ data }) => {
      navigate(`/skus/${data.id}`);
    },
  });

  const backendErrors = flattenErrorObject(
    updateSKUErrors?.response.data.errors
  );

  const onSubmit = (values) => {
    const formData = new FormData();
  
    const numericFields = ['height', 'width', 'length', 'weight', 'upc', 'low_inventory_quantity'];
    const integerFields = ['low_inventory_unit'];
  
    Object.entries(values).forEach(([key, value]) => {
      if (!['image', 'attachments', 'categories'].includes(key)) {
        if (key === 'category_ids') {
          value.forEach((category) => {
            formData.append('sku[category_ids][]', category.value);
          });
        } else if (numericFields.includes(key)) {
          // Convert numeric fields, but only if they have a value
          if (value !== null && value !== '') {
            const numValue = parseFloat(value);
            if (!isNaN(numValue)) {
              formData.append(`sku[${key}]`, numValue);
            }
          }
        } else if (integerFields.includes(key)) {
          // Convert integer fields, but only if they have a value
          if (value !== null && value !== '') {
            const intValue = parseInt(value, 10);
            if (!isNaN(intValue)) {
              formData.append(`sku[${key}]`, intValue);
            }
          }
        } else if (key === 'sku_warehouse_type') {
          // Handle sku_warehouse_type separately
          if (value !== null && value !== '') {
            // Convert the enum value back to its integer representation
            const warehouseTypeValue = Object.keys(warehouseTypeEnum).find(key => warehouseTypeEnum[key] === value);
            if (warehouseTypeValue) {
              formData.append(`sku[${key}]`, warehouseTypeValue);
            }
          }
        } else {
          // For all other fields, only append if they have a value
          if (value !== null && value !== '') {
            formData.append(`sku[${key}]`, value);
          }
        }
      }
    });
  
    // Handle attachments
    if (values.attachments && values.attachments.length > 0) {
      const newAttachments = [];
      values.attachments.forEach((attachment) => {
        if (attachment instanceof File) {
          newAttachments.push(attachment);
        } else if (attachment._destroy) {
          formData.append('sku[remove_attachments][]', attachment.signed_id);
        }
      });
  
      // Append new attachments
      newAttachments.forEach((file) => {
        formData.append('sku[new_attachments][]', file);
      });
    }
  
    updateSKUMutation(formData);
  };
  
  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  const defaultValues = {
    ...skuDetails,
    sku_warehouse_type: warehouseTypeEnum[skuDetails.sku_warehouse_type],
    category_ids: skuDetails.categories.map((item) => ({
      label: item.name,
      value: item.id,
    })),
    image: skuDetails.image || undefined,
    attachments: skuDetails.attachments || [],
  };

  return (
    <SKUFormPage
      onSubmit={onSubmit}
      backendErrors={backendErrors}
      isLoading={isUpdateSKULoading}
      defaultValues={defaultValues}
    />
  );
};

export default SKUEdit;