import { Box, IconButton, Image } from '@chakra-ui/react';
import useLanguage from 'hooks/useLanguage';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { FileInput } from './AttachmentUpload';

const StyledTrashIcon = styled(TrashIcon)`
  path {
    fill: #e53e3e;
  }
`;

const ImageInput = ({ watchImages = [], setValue, control }) => {
  const { language } = useLanguage();
  const isRTL = language === 'ar';
  console.log({ watchImages });
  return (
    <>
      {watchImages?.map((file, index) => {
        if (file._destroy) {
          return null;
        }

        let image;
        if (file instanceof File) {
          image = URL.createObjectURL(file);
        } else if (typeof file === 'object' && file.url) {
          image = file.url;
        } else {
          return null;
        }

        return (
          <Box key={index} position="relative" width="fit-content" mb={4}>
            <Image borderRadius="xl" src={image} maxH="150px" />
            <IconButton
              sx={{
                position: 'absolute',
                top: '-15px',
                right: isRTL ? 'auto' : '-10px',
                left: isRTL ? '-10px' : 'auto',
              }}
              variant="outline"
              colorScheme="red"
              background="red.50"
              size="xs"
              rounded="full"
              onClick={() => {
                const updatedImages = [...watchImages];
                updatedImages[index]._destroy = true;
                setValue('attachments', updatedImages);
              }}
              icon={<StyledTrashIcon width="18px" height="18px" />}
            />
          </Box>
        );
      })}
      <Controller
        control={control}
        name="attachments"
        render={({ field: { onChange, value, name } }) => (
          <FileInput
            accept="image/jpg, image/jpeg, image/png"
            name="attachments"
            multiple
            onChange={(files) => {
              onChange([...watchImages, ...files]);
            }}
          />
        )}
      />
    </>
  );
};

export default ImageInput;
