import { Spinner } from '@chakra-ui/react';
import { createSKU , getOrganizationThreshold} from 'api/Dashboard/skus';
import SKUFormPage from 'components/Dashboard/SKUs/SKUFormPage';
import { stringToNumber } from 'components/shared/Inputs/FileUpload/utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { flattenErrorObject } from 'utils/formError';
import { getLocalStorage } from 'utils/localStorage';

const SKUCreate = () => {
  const navigate = useNavigate();

  const user = getLocalStorage('user');
  const organizationId = user?.organization?.id;

  const { data: expiryThresholdData, isLoading: isThresholdLoading } = useQuery(
    ['organizationThreshold', organizationId],
    () => getOrganizationThreshold(organizationId),
    {
      enabled: !!organizationId,
      refetchOnMount: true,
    }
  );

  const expiryThresholdDefaultValue = expiryThresholdData && expiryThresholdData.organization_expiry_threshold_in_days !== null
  ? parseInt(expiryThresholdData.organization_expiry_threshold_in_days, 10)
  : 90;


  const {
    mutate: createSKUMutation,
    isLoading: isCreateSKULoading,
    error: createSKUErrors,
  } = useMutation(createSKU, {
    onSuccess: ({ data }) => {
      navigate(`/skus/${data.id}`);
    },
  });

  const backendErrors = flattenErrorObject(
    createSKUErrors?.response?.data?.errors || {}
  );

  if (isThresholdLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  const onSubmit = (values) => {
    const formData = new FormData();
  
    // Helper function to convert and append values
    const appendToFormData = (key, value) => {
      if (value !== undefined && value !== '') {
        if (['height', 'width', 'length', 'weight', 'upc', 'low_inventory_unit'].includes(key)) {
          value = stringToNumber(value);
          // Only append if value is not NaN
          if (!isNaN(value)) {
            formData.append(`sku[${key}]`, value);
          }
        } else if (key === 'sku_warehouse_type') {
          // Ensure sku_warehouse_type is a valid string enum value
          if (['partner', 'in_house'].includes(value)) {
            formData.append(`sku[${key}]`, value);
          }
        } else {
          formData.append(`sku[${key}]`, value);
        }
      }
    };
  
    // Append other form fields
    Object.entries(values).forEach(([key, value]) => {
      if (!['image', 'attachments', 'categories'].includes(key)) {
        if (key === 'category_ids') {
          if (value && Array.isArray(value)) {
            value.forEach((category) => {
              formData.append('sku[category_ids][]', category.value);
            });
          }
        } else {
          appendToFormData(key, value);
        }
      }
    });
  
    // Append attachment files
    if (values.attachments && values.attachments.length > 0) {
      values.attachments.forEach((attachment) => {
        if (attachment instanceof File) {
          formData.append('sku[attachments][]', attachment);
        }
      });
    }
  
    createSKUMutation(formData);
  };

  const defaultValues = {
    near_expiry_threshold: expiryThresholdDefaultValue,
  };

  return (
    <SKUFormPage
      onSubmit={onSubmit}
      backendErrors={backendErrors}
      isLoading={isCreateSKULoading}
      defaultValues={defaultValues}
    />
  );
};

export default SKUCreate;
