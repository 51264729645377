import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      cacheTime: 100,
      retry: (failureCount, error) => {
        if (failureCount === 3) {
          return false;
        }

        return true;
      },
    },
  },
});
